class NavSub {
  constructor() {
    this.dropdown = document.querySelectorAll(".dropdown");
    this.dropdownMenu = document.querySelector(".nav__item-dropdown");

    if (this.dropdown) {
      this.initNavSubz();
    }
  }

  initNavSubz() {
    let that = this;

    that.dropdown.forEach((targetDropdown) => {
      targetDropdown.addEventListener("click", () => {
        that.dropdown.forEach((dropdown) => {
          if (dropdown == targetDropdown) {
            if (!dropdown.classList.contains("js-dropdown-icon-active")) {
              dropdown.classList.add("js-dropdown-icon-active");
              dropdown.nextElementSibling.classList.add("js-dropdown-active");
            } else {
              dropdown.classList.remove("js-dropdown-icon-active");
              dropdown.nextElementSibling.classList.remove(
                "js-dropdown-active"
              );
            }
          }
        });
      });
    });
  }
}

new NavSub();
