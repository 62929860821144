class Accordian {
  constructor() {
    this.details = document.querySelectorAll("details");

    if (this.details) {
      this.initAccordian();
    }
  }

  initAccordian() {
    let that = this;
    // Add the onclick listeners.
    that.details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        // Close all the details that are not targetDetail.
        that.details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });
  }
}

new Accordian();
