// import ElementObserver from "./ElementObserver.js";

class Banner {
  constructor() {
    this.banner = document.querySelector("section.banner");
    this.bannerForm = document.querySelector(".banner__form-wrapper");
    this.bannerFormOpener = document.querySelector(".banner__form--opener");
    this.bannerFormOpener = document.querySelector(".banner__form--opener");
    this.bannerContent = document.querySelector(".banner__content");

    if (this.bannerFormOpener && this.bannerForm) {
      this.initBannerForm();
    }

    // this.bannerContent ? new ElementObserver(this.bannerContent) : false;
  }

  initBannerForm() {
    let that = this;
    that.bannerFormOpener.addEventListener("click", (event) => {
      if (that.bannerForm.classList.contains("form-active")) {
        that.bannerForm.classList.remove("form-active");
      } else {
        that.bannerForm.classList.add("form-active");
      }
    });
  }
}

new Banner();
