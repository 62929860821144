class SiteGDPR {
  constructor() {
    this.body = document.querySelector("body");
    this.gdprBox = document.getElementById("gdprInfo");
    this.gdprOverlay = document.querySelector(".gdpr-block__overlay");
    this.gdprOpener = document.querySelectorAll('a[href="#gdpr"]');
    this.gdprCloser = document.querySelector(".gdpr-block__closer-icon");
    this.gdprCloser = document.querySelector(".gdpr-block__closer-icon");
    this.gdprCloserBtn = document.querySelector("button.gdpr-block__closer");

    this.gdprPopupCheckups();
    this.checkGDPRhash();
  }

  gdprPopupCheckups() {
    let that = this;
    if (that.gdprOpener && this.gdprBox) {
      that.gdprOpener.forEach((element) => {
        element.onclick = (event) => {
          event.preventDefault();
          that.toggleGDPRPopup();
        };
      });
    }

    if (that.gdprOverlay) {
      that.gdprOverlay.onclick = () => {
        that.toggleGDPRPopup();
      };
    }

    if (that.gdprCloser) {
      that.gdprCloser.onclick = () => {
        that.toggleGDPRPopup();
      };
    }

    if (that.gdprCloserBtn) {
      that.gdprCloserBtn.onclick = () => {
        that.toggleGDPRPopup();
      };
    }
  }

  toggleGDPRPopup() {
    let that = this;
    if (!that.body.classList.contains("gdpr-active")) {
      that.body.classList.add("gdpr-active");
      window.location.hash = "#gdpr";
    } else {
      that.escapeKeyDown();
      that.body.classList.remove("gdpr-active");
    }
  }

  escapeKeyDown() {
    let that = this;

    if (
      that.body.classList.contains("gdpr-active") ||
      window.location.hash == "#gdpr"
    ) {
      document.onkeydown = (event) => {
        event = event || window.event;

        if (
          event.key === "Escape" ||
          event.key === "Esc" ||
          event.keyCode === 27
        ) {
          that.body.classList.remove("gdpr-active");
        }
      };
    }
  }

  checkGDPRhash() {
    let that = this;
    if (window.location.hash == "#gdpr") {
      that.body.classList.add("gdpr-active");
      that.escapeKeyDown();
    }
  }
}

new SiteGDPR();
