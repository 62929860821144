import HeaderNavigation from "./nav";
import debounce from "./debounce";
class Header {
  constructor() {
    this.body = document.querySelector("body");
    this.app = document.getElementById("app");
    this.header = document.querySelector("header.site-header");
    this.headerLogo = document.querySelector(".site-header__logo-wrapper");
    this.nav = document.querySelector("nav.nav__innerwrapper");
    this.callButton = document.querySelector(".site-header__call");
    this.headerHeight = this.header.offsetHeight;

    if (this.header) {
      this.initHeader();
    }
  }

  initHeader() {
    let that = this;

    // Check these fuctions when loads at first
    that.handleHeaderHeight();
    that.handleScrolledHeader();
    new HeaderNavigation();

    window.onresize = debounce(() => {
      that.handleHeaderHeight();
    }, 200);

    window.addEventListener("DOMContentLoaded", (event) => {
      that.handleHeaderHeight();
    });

    window.onscroll = debounce(() => {
      that.handleScrolledHeader();
    }, 50);

    // that.callButton.onmouseover = () => {
    //   that.handleHeaderCallusFormPopup("show");
    // };

    // that.callButton.onmouseout = () => {
    //   that.handleHeaderCallusFormPopup();
    // };
  }

  handleHeaderHeight() {
    let that = this;
    let newHeaderHeight = that.header.getBoundingClientRect().height;

    that.headerHeight = newHeaderHeight;
    that.app.style.marginTop = newHeaderHeight + "px";
    // that.header.style.height = newHeaderHeight + "px";
    that.nav.style.marginTop = newHeaderHeight + "px";
  }

  handleScrolledHeader() {
    let that = this;
    if (window.scrollY > 300) {
      that.app.classList.add("is-scrolled");
      that.headerLogo.classList.add("logo-reversed");
    } else {
      that.app.classList.remove("is-scrolled");
      that.headerLogo.classList.remove("logo-reversed");
    }
  }

  handleHeaderCallusFormPopup(process) {
    let that = this;
      if (process == "show") {
        that.body.classList.add("header-call-active");
      } else {
        that.body.classList.remove("header-call-active");
      }
  }
}

new Header();
