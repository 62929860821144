export default class HeaderNavigation {
  constructor() {
    this.siteHeader = document.querySelector("header.site-header");
    this.headerLogo = document.querySelector(".site-header__logo-wrapper");
    this.navBurger = document.querySelector(".js-nav-opener");
    this.navOverlay = document.querySelector(".nav__overlay");
    this.initNav();
  }

  initNav() {
    let that = this;
    that.navBurger.onclick = () => {
      that.toggleNavClasses();
    };

    that.navOverlay.onclick = () => {
      that.toggleNavClasses();
    };
  }

  toggleNavClasses() {
    if (!this.siteHeader.classList.contains("js-nav-active")) {
      this.siteHeader.classList.add("js-nav-active");
    } else {
      this.siteHeader.classList.remove("js-nav-active");
    }
  }
}
